<template>
  <div>
    <TeacherPageTableControl class="mb-5" />
    <AppPageCard>
      <template #card-title>
        {{ $t("TEACHER.HEADER") }}
      </template>
      <template #card-toolbar>
        <TeacherPageControl></TeacherPageControl
      ></template>
      <b-nav pills>
        <b-nav-item to="active" exact exact-active-class="active"
          >{{ "List" }}
        </b-nav-item>
        <b-nav-item to="archived" exact exact-active-class="active"
          >{{ "Archived" }}
        </b-nav-item>
      </b-nav>

      <div class="mt-5">
        <router-view></router-view>
      </div>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import TeacherPageControl from "@/modules/school/components/teacher/TeacherPageControl";
import TeacherPageTableControl from "@/modules/school/components/teacher/filter/TeacherPageTableControl";
export default {
  name: "SchoolTeacherPage",
  components: { TeacherPageTableControl, TeacherPageControl, AppPageCard },
};
</script>

<style scoped></style>
