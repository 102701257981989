import { render, staticRenderFns } from "./TeacherArchivedPageTableControl.vue?vue&type=template&id=14b6f2ea&scoped=true&"
import script from "./TeacherArchivedPageTableControl.vue?vue&type=script&lang=js&"
export * from "./TeacherArchivedPageTableControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b6f2ea",
  null
  
)

export default component.exports